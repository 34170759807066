<template>
  <div class="row pb-4">
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <label class="fw-bold text-gray-600 ps-0 pb-2">
      <slot name="titolo_doc"></slot>
    </label>
    <div class="col-lg-12 d-flex px-0">
      <label
        v-if="documento"
        class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
        data-kt-image-input-action="change"
        data-bs-toggle="tooltip"
        @click="
          downloadFile(documento ? documento.item_id : undefined, 'prova.pdf')
        "
      >
        <i class="bi bi-download fw-bold text-gray-700 fs-6"></i>
      </label>
      <div
        class="image-input image-input-outline"
        data-kt-image-input="true"
        style="background-image: url(media/avatars/blank.png)"
        v-if="!documento"
      >
        <label
          class="btn btn-icon btn-active-color-primary bg-white m-0 p-0"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          title="Change file"
        >
          <i class="bi bi-upload fw-bold text-gray-700 fs-6"></i>

          <!--begin::Inputs-->
          <input
            type="file"
            name="avatar"
            :accept="supportedFormatOmolog"
            @change="onInputChange($event)"
          />
          <input type="hidden" name="avatar_remove" />
          <!--end::Inputs-->
        </label>
      </div>
      <div class="d-flex w-100">
        <!-- :value="file.file ? file.file.name : ''" -->
        <input
          type="text"
          class="form-control"
          :class="!documento ? 'ms-10' : 'ms-2'"
          placeholder=""
          aria-label=""
          :value="
            documento ? documento.descrizione : file.file ? file.file.name : ''
          "
          disabled
        />
        <i
          v-if="
            documento &&
            (isEnabled('fnImpiantiDeleteDocumentoOmologazione') ||
              (isEnabled('fnImpiantiDeleteDocumentoOmologazioneSocieta') &&
                (id_stato == 5 ||
                  id_stato == 3 ||
                  stato == 'Richiesta approvazione' ||
                  stato == 'Rifiutata')))
          "
          class="bi bi-trash text-danger fs-5 ms-3 align-self-center"
          type="button"
          @click="eliminaFile(documento.item_id)"
        ></i>
        <i
          v-if="
            !documento ||
            (!isEnabled('fnImpiantiDeleteDocumentoOmologazione') &&
              !isEnabled('fnImpiantiDeleteDocumentoOmologazioneSocieta')) ||
            (!isEnabled('fnImpiantiDeleteDocumentoOmologazione') &&
              isEnabled('fnImpiantiDeleteDocumentoOmologazioneSocieta') &&
              id_stato != 5 &&
              stato != 'Richiesta approvazione' &&
              id_stato != 3 &&
              stato != 'Rifiutata')
          "
          class="bi bi-trash text-gray-500 fs-5 ms-3 align-self-center"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import useFileList from "@/composables/file-list";
import { sendFiles } from "@/requests/fileSender";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2";
import { deleteDocumento } from "@/requests/documentiOmologazioni";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { downloadElement } from "@/requests/downloadFiles";

import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "FileUploadOmolog",
  emits: ["fileUploaded"],
  components: { Loading },
  props: {
    id_Omologazione: {
      type: Number,
      required: true,
    },
    anno: {
      type: Number,
      required: true,
    },
    id_Tipologia_Documento: {
      type: Number,
      required: true,
    },
    descrizione: {
      type: Number,
      required: true,
    },
    documento: {
      type: Object,
    },
    stato: {
      type: String,
    },
    id_stato: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const { file, addFile, removeFile } = useFileList();
    async function onInputChange(e) {
      addFile(e.target.files, supportedFormatOmolog.value);
      e.target.value = null; // reset so that selecting the same file again will still cause it to fire this change
      await uploadFile();
    }
    const supportedFormatOmolog = ref(".pdf");

    const uploadFile = async () => {
      let bodyFormData = new FormData();

      if (
        !props.documento ||
        props.documento.item_id === undefined ||
        props.documento.item_id === null
      ) {
        bodyFormData.append("id_Omologazione", props.id_Omologazione);
      } else {
        bodyFormData.append("item_Id", props.documento.item_id);
      }

      bodyFormData.append("anno", props.anno);
      bodyFormData.append(
        "id_Tipologia_Documento",
        props.id_Tipologia_Documento
      );
      bodyFormData.append("descrizione", file.value.file.name);
      bodyFormData.append("file", file.value.file);
      await sendFiles(
        bodyFormData,
        !props.documento ||
          props.documento.item_id === undefined ||
          props.documento.item_id === null
          ? globalApi.ADD_DOCUMENTO_OMOLOGAZIONI
          : globalApi.EDIT_DOCUMENTO_OMOLOGAZIONI
      ).then((res) => {
        if (res.status != 200) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          return;
        }
        alertSuccess("File caricato con successo");
        emit("fileUploaded");
      });

      bodyFormData = new FormData();
      file.value = {};
    };

    const isLoading = ref(false);
    const eliminaFile = (idDoc) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Una volta cancellato, non portai piu recuperare il file!",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          if (!idDoc) {
            alertFailed("Il documento non esiste!");
            return;
          }
          await deleteDocumento(idDoc).then(async (res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("File eliminato con successo");
              emit("fileUploaded");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };
    const downloadingFile = ref(false);
    const downloadFile = async (id, estensione) => {
      if (!id) {
        alertFailed("Nessun documento caricato");
        return;
      }
      if (!downloadingFile.value) {
        downloadingFile.value = true;
        // omologazione
        await downloadElement(
          id,
          "omologazione",
          estensione.substring(estensione.length - 3, estensione.length)
        );
        // .then((res) => {
        //   if (res.status != 200) {
        //     alertFailed("Non Riesco a scaricare il documento");
        //   }
        // });
        downloadingFile.value = false;
      }
    };
    return {
      file,
      addFile,
      removeFile,
      onInputChange,
      uploadFile,
      supportedFormatOmolog,
      eliminaFile,
      isLoading,
      downloadFile,
      isEnabled,
    };
  },
};
</script>

<style scoped>
.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
