import services from "@/axios/dbManag";

export const deleteDocumento = (item_Id) => {
  return services.dbManag
    .post("/omologazioni/impianti/documenti/del", {
      item_Id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => e.response);
};
