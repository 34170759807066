<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_doc_impianti"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Documenti omologazione</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('clear')"
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="mb-7 bg-secondary px-8 py-6 rounded">
              <div class="blueFit fs-8">
                <b
                  >ATTENZIONE! E' necessario caricare un documento per ciascuna
                  tipologia:</b
                >
                <ul>
                  <li>
                    Documentazione tecnico urbanistica (planimetria generale
                    dell’impianto sportivo)
                  </li>
                  <li>
                    Elaborati tecnici quotati per l’individuazione degli
                    impianti:
                  </li>
                  <li>
                    Planimetria generale in scala 1:500, dalla quale risulti:
                    <br />
                    a) la destinazione e le dimensioni delle varie aree (di
                    gioco, per il pubblico, per la Direzione di Gara, per
                    spogliatoi e servizi per gli atleti e per gli Arbitri, per
                    Pronto Soccorso ed Antidoping, ecc.); <br />b) le
                    delimitazioni dei campi di gioco, la disposizione delle
                    stesse e le dimensioni di ciascun campo di gioco; <br />c)
                    le dimensioni degli spazi di disimpegno attorno alle aree di
                    gioco. <br />d) piante e sezioni in scala 1:100.
                  </li>
                </ul>
              </div>
            </div>
            <div class="ps-5 pe-5">
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="1"
                descrizione="Documentazione tecnico urbanistica"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.tecnico_urbanistica"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc
                  >Documentazione tecnico urbanistica</template
                >
              </FileUploadOmolog>
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="2"
                descrizione="Certificato di agibilità"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.certificato_agiabilita"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc>Certificato di agibilità</template>
              </FileUploadOmolog>
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="3"
                descrizione="Elaborati tecnici"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.elaborati_tecnici"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc>Elaborati tecnici</template>
              </FileUploadOmolog>
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="4"
                descrizione="Parere CONI in linea tecnico sportiva"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.parere_CONI"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc
                  >Parere CONI in linea tecnico sportiva (solo per impianti
                  costruiti dopo il 01/01/2021)</template
                >
              </FileUploadOmolog>
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="5"
                descrizione="Certificato di Agibilità rilasciato dalla Commissione di Vigilanza"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.certificato_agiabilita_commissione"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc
                  >Certificato di Agibilità rilasciato dalla Commissione di
                  Vigilanza</template
                >
              </FileUploadOmolog>
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="6"
                descrizione="Verbale ufficio omologazioni"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.verbale_ufficio_omologazioni"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc>Verbale ufficio omologazioni</template>
              </FileUploadOmolog>
              <!-- <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="8"
                descrizione="Altro"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.verbale_tecnico_omologatore"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc>Verbale del tecnico omologatore</template>
              </FileUploadOmolog> -->
              <FileUploadOmolog
                :id_Omologazione="id_Omologazione"
                :anno="stagioneSelected"
                :id_Tipologia_Documento="7"
                descrizione="Altro"
                @fileUploaded="getDocumentiOmologList"
                :documento="documenti.altro"
                :stato="stato"
                :id_stato="id_stato"
              >
                <template #titolo_doc>Altro</template>
              </FileUploadOmolog>
              <hr />
              <div
                v-if="isEnabled('fnImpiantiDownloadVerbaleVisitaOmologazione')"
                class="row pb-4 text-center text-hover-primary"
              >
                <a
                  href="/media/fit-media/Documenti/Verbale di visita impianto.docx"
                >
                  <i class="bi bi-download text-dark fw-bold"> </i> &nbsp;
                  Scarica verbale di visita
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getCurrentInstance, watch, computed, ref } from "vue";
import FileUploadOmolog from "@/components/components-fit/impianti/FileUploadOmolog.vue";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "ModalDocImpianti",
  components: { FileUploadOmolog, Loading },
  emits: ["clear"],
  props: {
    id_Omologazione: {
      type: Number,
      required: true,
    },
    denominazione: {
      type: String,
      required: true,
    },
    stato: {
      type: String,
    },
    id_stato: {
      type: Number,
    },
    refresh: {
      type: Boolean,
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const store = useStore();
    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const isLoading = ref(false);
    const getDocumentiOmologList = async () => {
      isLoading.value = true;
      documenti.value = {};
      store.commit(
        "spliceArrayFromName",
        "resultsomologazione_impianto_documenti_list"
      );
      await store.dispatch("setStoreListData", {
        keys: {
          id_Omologazione: props.id_Omologazione,
        },
        apiLink: globalApi.LISTA_DOCUMENTI_OMOLOGAZIONI,
        itemName: "omologazione_impianto_documenti_list",
        splice: true,
      });

      setDocObjects();
      isLoading.value = false;
    };
    const results = computed(() =>
      store.getters.getStateFromName(
        "resultsomologazione_impianto_documenti_list"
      )
    );
    const documenti = ref({
      tecnico_urbanistica: {},
      certificato_agiabilita: {},
      elaborati_tecnici: {},
      parere_CONI: {},
      certificato_agiabilita_commissione: {},
      verbale_ufficio_omologazioni: {},
      verbale_tecnico_omologatore: {},
      altro: {},
    });

    const setDocObjects = () => {
      results.value.forEach((element) => {
        switch (element.id_tipologia) {
          case 1:
            documenti.value.tecnico_urbanistica = { ...element };
            break;
          case 2:
            documenti.value.certificato_agiabilita = { ...element };
            break;
          case 3:
            documenti.value.elaborati_tecnici = { ...element };
            break;
          case 4:
            documenti.value.parere_CONI = { ...element };
            break;
          case 5:
            documenti.value.certificato_agiabilita_commissione = { ...element };
            break;
          case 6:
            documenti.value.verbale_ufficio_omologazioni = { ...element };
            break;
          case 7:
            documenti.value.altro = { ...element };
            break;
          case 8:
            documenti.value.verbale_tecnico_omologatore = { ...element };
            break;
          default:
        }
      });
    };

    watch(() => props.id_Omologazione);
    watch(
      () => props.refresh,
      () => {
        getDocumentiOmologList();
      }
    );

    return {
      getDocumentiOmologList,
      documenti,
      stagioneSelected,
      results,
      isLoading,
      isEnabled,
      loaded: computed(() =>
        store.getters.getStateFromName(
          "loadedomologazione_impianto_documenti_list"
        )
      ),
      record: computed(() =>
        store.getters.getStateFromName(
          "recordomologazione_impianto_documenti_list"
        )
      ),
      status: computed(() =>
        store.getters.getStateFromName(
          "statusomologazione_impianto_documenti_list"
        )
      ),
    };
  },
};
</script>

<style scoped>
.modal-body {
  padding: 0px;
}

@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

/* .image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
} */
</style>
